import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Info } from '../model/info';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  constructor(private readonly http: HttpClient) {}

  getInfo$(): Observable<Info> {
    return this.http.get<any[]>('/api/info').pipe(map(data => new Info(data)));
  }
}
