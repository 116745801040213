import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Auth2Service } from './auth2.service';

/**
 * https://stackoverflow.com/questions/40020703/angular2-redirect-to-calling-url-after-successful-login
 */
@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private auth2Service: Auth2Service
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // The url from the client
    let url: string = state.url;

    return this.checkLogin(url);
  }

  private checkLogin(url) {
    if (this.auth2Service.isLoggedIn) {
      return true;
    }

    // Store the attempted URL for redirecting
    this.auth2Service.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(['/login']);
    return false;
  }
}
