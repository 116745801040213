import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TopClimatInvoiceableInterventions } from '../model/top-climat-invoiceable-interventions';
import { TopClimatConfig } from '../model/top-climat-config';

@Injectable({
  providedIn: 'root'
})
export class TopClimatService {
  constructor(private readonly http: HttpClient) {}

  getConfig$(): Observable<TopClimatConfig> {
    return this.http
      .get<unknown>('/api/topclimat/config')
      .pipe(map(TopClimatConfig.fromJson));
  }

  getInterventions$(): Observable<{
    readonly interventions: TopClimatInvoiceableInterventions[];
    readonly progress: number;
  }> {
    return this.http
      .get<{ interventions: any[]; progress: number }>(
        '/api/topclimat/interventions'
      )
      .pipe(
        map(result => ({
          interventions: result.interventions.map(
            intervention => new TopClimatInvoiceableInterventions(intervention)
          ),
          progress: result.progress
        }))
      );
  }

  createInvoice$(interventionId: string, bexioOwnerId?: number | undefined) {
    return this.http.post(`/api/topclimat/invoices`, {
      interventionId,
      bexioOwnerId
    });
  }

  hideInterventions$(invoiceable: TopClimatInvoiceableInterventions) {
    return this.http.post<void>('/api/topclimat/hide', {
      ...invoiceable,
      logifleetInterventions: invoiceable.logifleetInterventions.map(
        intervention => ({
          ...intervention,
          event: { id: intervention.event.id }
        })
      )
    });
  }

  clearCache$() {
    return this.http.delete<unknown>('/api/topclimat/cache');
  }
}
