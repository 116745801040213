import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class JwtService implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token_tsync');
    if (req.url.includes('api')) {
      if (token) {
        const headers = {
          Authorization: token
        };

        const clone = req.clone({ setHeaders: headers });

        return next.handle(clone);
      } else {
        // /api/password doesn't required token
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}
