<footer class="container-fluid mt-3 mb-3 text-muted text-center">
  <small>
    Square Services Sàrl &copy; {{ year }} - TSync
    <ng-container *ngIf="info">
      {{ ' ' }}
      | Env:
      {{ info.environment }} | Version: {{ info.version }} | Build:
      {{ info.buildtime | date: 'medium' }} | Run:
      {{ info.startTime | amTimeAgo }}
    </ng-container>
  </small>
</footer>
