import { LogifleetBusinessEvent } from './logifleet-business-event';
import { LogifleetSite } from './logifleet-site';

export class LogifleetIntervention {
  readonly id: string;
  readonly event: LogifleetBusinessEvent;
  readonly site?: LogifleetSite;

  constructor(data: any) {
    this.id = data.id;
    this.event = new LogifleetBusinessEvent(data.event);
    this.site = data.site ? new LogifleetSite(data.site) : undefined;
  }

  get usedItems() {
    return this.event.completionData.usedItems;
  }
}
