import { Component, OnDestroy, OnInit } from '@angular/core';
import { BexioService } from '../services/bexio.service';
import { Router } from '@angular/router';
import { Auth2Service } from '../auth/auth2.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  waitingForAuth = true;

  bexioSettings: any = {};

  subscription: Subscription;
  connecterObservable: Subscription;

  constructor(
    private bexioService: BexioService,
    private auth2Service: Auth2Service,
    private router: Router
  ) {}

  ngOnInit() {
    // Get the grand url
    // this.bexioSettings = this.bexioService.statusSubject.getValue();
    this.subscription = this.bexioService.status$.subscribe(data => {
      this.bexioSettings = data;
      if (this.bexioSettings && this.bexioSettings.grantUrl) {
        if (!this.auth2Service.isLoggedIn) {
          window.location.href = this.bexioSettings.grantUrl;
        }
      }
    });
    // Redirect when connected
    this.connecterObservable = this.auth2Service.connected().subscribe(
      (connected: boolean) => {
        if (connected) {
          this.router.navigate(['/logifleet']);
        }
      },
      () => (this.waitingForAuth = false)
    );

    this.bexioService.init();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.connecterObservable.unsubscribe();
  }
}
