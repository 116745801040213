import { compact } from 'lodash';

export class BexioContact {
  readonly id: number;
  readonly name_1: string;
  readonly name_2: string;
  readonly nr: number;
  readonly address?: string;
  readonly zipCode?: string;
  readonly city?: string;

  constructor(data: any) {
    this.id = data.id;
    this.name_1 = data.name_1;
    this.name_2 = data.name_2;
    this.nr = data.nr;
  }

  get fullAddress() {
    const { address, zipCode, city } = this;
    const zipCodeAndCity = compact([zipCode, city]).join(' ');
    const parts = compact([address, zipCodeAndCity]);
    return parts.length === 0 ? undefined : parts.join(', ');
  }

  get fullName() {
    const { name_1, name_2 } = this;
    const parts = compact([name_1, name_2]);
    return parts.length === 0 ? undefined : parts.join(' ');
  }
}
