import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { SharedModule } from '../shared/shared.module';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';

@NgModule({
  imports: [SharedModule],
  providers: [],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    BlankLayoutComponent
  ],
  exports: [LayoutComponent, HeaderComponent, FooterComponent]
})
export class LayoutModule {}
