export class Info {
  readonly environment: string;
  readonly version: string;
  readonly buildtime: string;
  readonly startTime: string;

  constructor(data: any) {
    this.environment = data.environment;
    this.version = data.version;
    this.buildtime = data.buildtime;
    this.startTime = data.startTime;
  }
}
