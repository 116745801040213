<div class="wrapper">
  <!-- top navbar-->
  <section class="section-container">
    <!-- Page content-->
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </section>
  <!-- Page footer-->
</div>
