import { Keys } from '../utils/types';

export class TopClimatConfig {
  static fromJson(json: unknown) {
    return new TopClimatConfig(json as Keys<TopClimatConfig>);
  }

  readonly specialArticles: string[];

  constructor(data: Keys<TopClimatConfig>) {
    this.specialArticles = data.specialArticles;
  }
}
