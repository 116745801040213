import { Component, OnInit } from '@angular/core';
import { Auth2Service } from './auth/auth2.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private readonly auth2Service: Auth2Service) {}

  ngOnInit() {
    this.auth2Service.loadUser();
  }
}
