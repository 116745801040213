import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { jwtDecode as JWT } from 'jwt-decode';
import { LoginUser } from '../model/login-user';
import { BexioService } from '../services/bexio.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Auth2Service {
  private isConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private userSubject: BehaviorSubject<User> = new BehaviorSubject(null);

  token: Subject<string | undefined> = new Subject<string>();
  loggedUser: User;
  redirectUrl: string;

  isLoggedIn = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private bexioService: BexioService
  ) {
    this.token.subscribe(token => {
      this.loadConnectedUser(token);
    });
  }

  user(): Observable<User> {
    return this.userSubject.asObservable();
  }

  connected(): Observable<boolean> {
    return this.isConnected.asObservable();
  }

  login(username: string, password: string) {
    return this.http
      .post('/jwtlogin', new LoginUser(username, password), {
        observe: 'response',
        responseType: 'text'
      })
      .pipe(
        map(response => {
          let jwtToken = response.headers.get('Authorization');
          if (jwtToken) {
            // store jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token_tsync', jwtToken);
            this.token.next(jwtToken);
          }
        })
      );
  }

  isTokenExpired() {
    let strToken = localStorage.getItem('token_tsync');
    if (strToken) {
      let myToken: any = JWT(strToken);
      let current_time = new Date().getTime() / 1000;
      if (current_time > myToken.exp) {
        return true;
      }
    }
    return false;
  }

  logout() {
    // remove user from local storage to log user out
    this.http.get('/api/bexio/logout').subscribe(
      data => {
        localStorage.removeItem('token_tsync');
        this.isConnected.next(false);
        this.token.next(undefined);
        this.userSubject.next(null);
        this.isLoggedIn = false;

        // get the new grant URL
        this.bexioService.statusSubject.next(data);

        this.router.navigate(['/login']);
      },
      () => {
        // If logout fails, force token reload
        localStorage.removeItem('token_tsync');
      }
    );
  }

  loadUser() {
    this.token.next(localStorage.getItem('token_tsync'));
  }

  private loadConnectedUser(token?: string) {
    if (token && token !== '') {
      this.http.get('/api/bexio/user').subscribe(user => {
        this.loggedUser = new User(user);
        this.isConnected.next(true);
        this.userSubject.next(this.loggedUser);
        this.isLoggedIn = true;

        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
          this.redirectUrl = null;
        }
      });
    }
  }
}
