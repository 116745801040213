export class User {
  id: number;
  nickname: string;
  firstname: string;
  lastname: string;
  email: string;
  color: string;
  phone_fixed: string;
  phone_mobile: string;
  is_superadmin: boolean;

  constructor(data: any) {
    if (data) {
      this.id = data.id;
      this.nickname = data.nickname;
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.email = data.email;
      this.color = data.color;
      this.phone_fixed = data.phone_fixed;
      this.phone_mobile = data.phone_mobile;
      this.is_superadmin = data.is_superadmin;
    }
  }
}
