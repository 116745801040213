import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Auth2Service } from './auth/auth2.service';

@Injectable()
export class HttpErrorHandler extends ErrorHandler {
  constructor(
    private readonly injector: Injector,
    private readonly ngZone: NgZone
  ) {
    super();
  }

  get auth2Service(): Auth2Service {
    return this.injector.get(Auth2Service);
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error) {
    if (error?.status == 401 || error?.status == 403) {
      if (!this.auth2Service.isTokenExpired()) {
        this.showError(
          'Accès non autorisé',
          "Veuillez vérifier votre mot de passe ou si vous avez le droit d'accèder à cette page. (" +
            error.statusText +
            ')'
        );
      } else {
        this.showError(
          'Accès non autorisé',
          'Votre compte est actuellement bloqué'
        );
      }
      this.auth2Service.logout();

      this.router.navigate(['/login']);

      return;
    }
    console.error(error);
    // this.auth2Service.logout(); // force cleanup token

    if (error?.error) {
      // let data = JSON.parse(error.error);
      this.showError(
        'Cette opération a produit une erreur.',
        error.error.exception
      );
    } else if (error) {
      this.showError(
        'Oups !',
        'Veuillez contacter le support, si cette erreur réapparait. ' +
          error.status +
          ' (' +
          error.statusText +
          ')'
      );
    }

    return error;
  }

  // https://stackoverflow.com/questions/44975477/angular2-ng-toasty-errorhandler
  private showError(title: string, body: string) {
    this.ngZone.run(() => {
      this.toastrService.error(title, body);
    });
  }
}
