import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  FaIconLibrary,
  FontAwesomeModule
} from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';

import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { JwtService } from './auth/jwt.service';
import { HttpErrorHandler } from './http-error-handler.service';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth-guard.service';
import { WelcomeComponent } from './login/welcome/welcome.component';
import { BexioService } from './services/bexio.service';
import { InfoService } from './services/info.service';
import { TopClimatService } from './services/top-climat.service';
import {
  faAnglesUp,
  faArrowDown,
  faArrowUp,
  faBan,
  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCheck,
  faExternalLink,
  faGear,
  faMoneyBill,
  faPlus,
  faRefresh,
  faRemove,
  faSearch,
  faSignIn,
  faSignOut,
  faTriangleExclamation,
  faWarning,
  faWrench
} from '@fortawesome/free-solid-svg-icons';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [AppComponent, LoginComponent, WelcomeComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    LayoutModule,
    ToastrModule.forRoot(),
    SharedModule.forRoot(),
    RoutesModule
  ],
  providers: [
    AuthGuard,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: ErrorHandler, useClass: HttpErrorHandler },
    JwtService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtService, multi: true },
    BexioService,
    InfoService,
    TopClimatService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(
      faAnglesUp,
      faArrowDown,
      faArrowUp,
      faBan,
      faCalendar,
      faCaretDown,
      faCaretLeft,
      faCheck,
      faExternalLink,
      faGear,
      faMoneyBill,
      faPlus,
      faRefresh,
      faRemove,
      faSearch,
      faSignIn,
      faSignOut,
      faTriangleExclamation,
      faWarning,
      faWrench
    );
  }
}
