export class LogifleetBusinessEvent {
  readonly id: string;
  readonly completionData: {
    readonly fields: {
      readonly id: string;
      readonly value: string;
    }[];
    readonly lifecycleTransitionDates: {
      readonly name: string;
      readonly date: string;
    }[];
    readonly usedItems: LogifleetUsedItem[];
  };
  readonly coreData: {
    readonly description: string;
    readonly priority: number;
    readonly creationDate: string;
  };

  constructor(data: any) {
    this.id = data.id;
    this.completionData = data.completionData;
    this.coreData = data.coreData;
  }

  get completionDate() {
    return this.completionData.lifecycleTransitionDates.find(
      date => date.name === 'completionDate'
    )?.date;
  }
}

export interface LogifleetUsedItem {
  readonly id: string;
  readonly name: string;
  readonly reference: string | null;
  readonly quantity: number | null;
  readonly price: number | null;
}
