import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth2Service } from '../../auth/auth2.service';

@Component({
  selector: 'app-welcome',
  template: '<h2>Nous chargeons vos données</h2>'
})
export class WelcomeComponent implements OnInit {
  // userWatcher: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private auth2Service: Auth2Service,
    private router: Router
  ) {}

  ngOnInit() {
    // this.userWatcher = this.auth2Service.user().subscribe(user => {
    //     this.router.navigate(['/']);
    // });

    // Grab token from url if exists
    const jwt = this.activatedRoute.snapshot.queryParams['jwt'];
    if (jwt) {
      // set the token then perform the "user load"
      localStorage.setItem('token_tsync', jwt);
      this.auth2Service.loadUser();
      this.router.navigate(['/logifleet']);
    }
  }

  //
  // ngOnDestroy(): void {
  //     this.userWatcher.unsubscribe;
  // }
}
