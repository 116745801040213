<nav class="navbar navbar-expand-lg navbar-light bg-light" role="navigation">
  <div class="container-fluid">
    <a class="navbar-brand" href="/">TopClimat SA</a>

    <ul class="navbar-nav">
      <li class="nav-item">
        <button type="button" class="nav-link btn btn-link" (click)="logout()">
          <fa-icon icon="sign-out" class="me-1"></fa-icon>
          {{ user?.firstname }} {{ user?.lastname }}
        </button>
      </li>
    </ul>
  </div>
</nav>
