import { Component, OnInit } from '@angular/core';
import { Info } from '../../model/info';
import { InfoService } from '../../services/info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  info: Info;
  readonly year: number;

  constructor(private readonly infoService: InfoService) {
    this.year = new Date().getFullYear();
  }

  ngOnInit() {
    this.infoService.getInfo$().subscribe(info => (this.info = info));
  }
}
