import { LayoutComponent } from '../layout/layout.component';
import { AuthGuard } from '../auth/auth-guard.service';
import { LoginComponent } from '../login/login.component';
import { BlankLayoutComponent } from '../layout/blank-layout/blank-layout.component';
import { WelcomeComponent } from '../login/welcome/welcome.component';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'logifleet', pathMatch: 'full' },
      {
        path: 'logifleet',
        loadChildren: () =>
          import('../dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },

  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'welcome', component: WelcomeComponent }
    ]
  },

  // Not found
  { path: '**', redirectTo: 'login' }
];
