import { first, flatMap, last } from 'lodash';
import { BexioContact } from './bexio-contact';
import { LogifleetIntervention } from './logifleet-intervention';

export class TopClimatInvoiceableInterventions {
  readonly interventionId: string;
  readonly bexioContact?: BexioContact;
  bexioInvoiceId?: string;
  readonly logifleetInterventions: LogifleetIntervention[];

  constructor(data: any) {
    this.interventionId = data.interventionId;
    this.bexioContact = data.bexioContact
      ? new BexioContact(data.bexioContact)
      : undefined;
    this.bexioInvoiceId = data.bexioInvoiceId ?? undefined;
    this.logifleetInterventions = data.logifleetInterventions.map(
      intervention => new LogifleetIntervention(intervention)
    );
  }

  get firstIntervention() {
    return first(this.logifleetInterventions);
  }

  get lastIntervention() {
    return last(this.logifleetInterventions);
  }

  get site() {
    return this.logifleetInterventions[0].site;
  }

  get usedItems() {
    return flatMap(
      this.logifleetInterventions,
      intervention => intervention.usedItems
    );
  }
}
