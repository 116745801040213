import { compact } from 'lodash';

export class LogifleetSite {
  readonly name?: string;
  readonly address?: string;
  readonly zipCode?: string;
  readonly city?: string;
  readonly contact?: string;
  readonly description?: string;
  constructor(data: any) {
    this.name = data.name;
    this.address = data.address;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.contact = data.contact;
    this.description = data.description;
  }

  get fullAddress() {
    const { address, zipCode, city } = this;
    const zipCodeAndCity = compact([zipCode, city]).join(' ');
    const parts = compact([address, zipCodeAndCity]);
    return parts.length === 0 ? undefined : parts.join(', ');
  }
}
