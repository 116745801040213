import { Component, OnInit, ViewChild } from '@angular/core';
import { Auth2Service } from '../../auth/auth2.service';
import { User } from '../../model/user';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: User;

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton') fsbutton; // the fullscreen button

  constructor(
    public auth2Service: Auth2Service,
    private router: Router
  ) {}

  ngOnInit() {
    this.isNavSearchVisible = false;
    this.auth2Service.user().subscribe(data => (this.user = data));
  }

  logout() {
    this.auth2Service.logout();
  }
}
