import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BexioService {
  readonly status$: Observable<any>;
  readonly statusSubject: BehaviorSubject<any>;

  constructor(private readonly http: HttpClient) {
    this.statusSubject = new BehaviorSubject(null);
    this.status$ = this.statusSubject.asObservable();
  }

  init() {
    this.http.get('/api/bexio/status').subscribe(data => {
      return this.statusSubject.next(data);
    });
  }

  getContacts$(typeFilter: string) {
    let query = '';
    if (typeFilter) {
      query = '?type=' + typeFilter;
    }

    return this.http.get('/api/bexio/contacts' + query);
  }

  logout$() {
    return this.http.get('/api/bexio/logout');
  }

  getContact$(contactId: number) {
    return this.http.get('/api/bexio/contacts/' + contactId);
  }

  clearCache$() {
    return this.http.put<unknown>(
      '/api/bexio/clearCacheAndGetInterventions-cache',
      null
    );
  }
}
